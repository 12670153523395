



























































































import { columna } from "@/shared/dtos/dynamic_tables/columna";
import { pantalla_modo_lista } from "@/shared/dtos/dynamic_tables/pantalla_modo_lista";
import { tipo_de_dato } from "@/shared/dtos/dynamic_tables/tipo_de_dato";
import { MessageService } from "@/shared/services/message-service";
import pantalla_modo_listaModule from "@/store/modules/pantalla_modo_lista-module";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {
    MaterialCard: () => import("@/components/Cards/MaterialCard.vue"),
    Xbtn: () => import("@/components/buttons/x-btn.vue"),
  },
  $_veeValidate: { validator: "new" },
})
export default class Dynamic_Tables_Fromulario extends Vue {
  public datos_lista: tipo_de_dato[] = [];
  async created() {
    await pantalla_modo_listaModule.getpantalla_modo_lista(
      this.$route.params.id
    );
    this.datos_lista = await pantalla_modo_listaModule.getTiposDatos();
  }

  public get datasource() {
    return pantalla_modo_listaModule.pantalla_modo_lista;
  }

  public get headers() {
    return [
      {
        text: "Id",
        value: "id",
      },
      ,
      { text: "Nombre", value: "nombre" },
      { text: "Data bilding", value: "data_bilding" },
      { text: "Orden", value: "orden" },
      {
        text: "Tipo de dato",
        value: "tipo_de_dato.nombre",
      },
      { text: "Pantalla", value: "id_pantalla_lista" },
      { text: "Actions", value: "actions" },
    ];
  }

  update_column(columna: columna) {
    pantalla_modo_listaModule.modificarColumnaPantalla(columna);
  }
  async cancel_column(columna: columna) {
    let index = this.datasource.columnas.findIndex((x) => x.id === columna.id);
    this.$set(
      this.datasource.columnas,
      index,
      await pantalla_modo_listaModule.get_columna_pantalla(columna.id)
    );
  }
  add_column(columna: columna) {
    pantalla_modo_listaModule.guardarColumnaPantalla(columna);
  }
  async add_new_column() {
    let id_columna = await pantalla_modo_listaModule.getNextIdColumna();
    let id_pantalla = this.$route.params.id;
    if (this.is_new()) {
      id_pantalla = await pantalla_modo_listaModule.getNextIdPantalla();
    }

    let column = new columna({
      id: id_columna,
      nombre: "",
      data_bilding: "",
      orden: this.datasource.columnas.length + 1,
      tipo_de_dato: this.datos_lista.find((x) => x.id === 1)!,
      id_tipo_dato: 1,
      id_pantalla_lista: id_pantalla,
    });

    pantalla_modo_listaModule.guardarColumnaPantalla(column);

    this.datasource.columnas.push(column);
  }

  public is_new() {
    return Number.parseInt(this.$route.params.id) === -1;
  }

  public aceptar() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        if (this.is_new()) {
            //pantalla_modo_listaModule.guardarpantalla_modo_lista(this.datasource.pantalla)
        } else {
        }
      }
    });
  }

  public cancelar() {
    this.$router.back();
  }

  eliminar() {
    MessageService.confirm(
      "¿Seguro que desea eliminar?",
      "Eliminar",
      (cb: boolean) => {
        if (cb) {
          pantalla_modo_listaModule.eliminarpantalla_modo_lista(
            new pantalla_modo_lista({ id: this.$route.params.id })
          );
        }
      }
    );
  }
}
